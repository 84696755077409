import React from "react";
import { Link } from "@reach/router";

// Components
import Modal from "react-bootstrap/Modal";

const VideoModal = (props) => (
  <Modal show={props.show} onHide={props.handleClose} size={props.modalSize}>
    <Modal.Header closeButton>
      <Modal.Title id={`video-title-${props.VimeoID}`}>
        {props.titleLink ? (
          <Link to={props.titleLink} id={`vimeo-link-${props.VimeoID}`}>
            {props.Title}
          </Link>
        ) : (
          <a
            href={`https://vimeo.com/${props.VimeoID}${props.TitleLink}`}
            target="_blank"
            rel="noopener noreferrer"
            id={`vimeo-link-${props.VimeoID}`}
          >
            {props.Title}
          </a>
        )}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ padding: "56% 0 0 0" }}>
      <iframe
        title={props.Title}
        className={`${props.frameClass} position-absolute d-block`}
        style={{ top: 0, left: 0 }}
        frameBorder="0"
        allow="autoplay"
        src={`https://player.vimeo.com/video/${props.VimeoID}?autoplay=1`}
      ></iframe>
    </Modal.Body>
  </Modal>
);
export default VideoModal;

VideoModal.defaultProps = {
  id: 1,
  frameClass: "w-100 h-100",
  handleClose: "", // close handler callback
  show: "", // open handler callback
  VimeoID: "", // Use this for Vimeo videos (possibly from Strapi)
  VideoURL: "", // currently not used (possibly from Strapi)
  Title: "",
  TitleLink: "",
  modalSize: "lg" // sm, lg, xl
};
