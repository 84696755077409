import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import VideoModal from "../../../components/video-modal/video-modal";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import LogoSeattleStorm from "../../../images/third-party/seattle-storm/seattle-storm-logo.svg";

const WaFdSports = () => {
  const [showKrakenVideo, setShowKrakenVideo] = useState(false);
  const handleCloseKrakenVideo = () => setShowKrakenVideo(false);
  const handleShowKrakenVideo = (e) => {
    e.preventDefault();
    setShowKrakenVideo(true);
  };
  const title = "Seattle Sport Team Partnerships";
  const description =
    "Learn about the top Seattle professional sports teams including Seattle Kraken, Seattle Storm, and Seattle Seawolves and how they partner with WaFd Bank.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/about-us/wafd-sports/hero-wafd-sports-052924-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "wafd-sports-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd Bank presenting a check to Hawthorne Elementary with Seattle Storm as part of the Fit Futures program.",
    heroBody: {
      components: [
        {
          id: "hero-heading-01",
          heading: {
            text: "Proud Banking Partner for Seattle Sports",
            class: "text-white"
          }
        },
        {
          id: 2,
          subheading: {
            text: "Committed to helping our communities win!",
            class: "text-white"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/wafd-sports"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-wafd-sports-052924.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "WaFd Sports"
    }
  ];

  const stefanFreiPaintingVideo = {
    title: "Stefan Frei Painting Kraken Video",
    vimeoId: "750092375",
    minHeight: "300px",
    class: "m-auto iframe w-100 border-radius-12 mb-3 mb-md-0"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1 className="mb-0">Seattle Sport Team Partnerships</h1>
      </section>
      {/* Seattle Kraken */}
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 text-center">
              <h2>Seattle Kraken</h2>
              <Link id="clickable-kraken-image" to="/about-us/wafd-sports/seattle-kraken">
                <StaticImage
                  src="../../../images/third-party/seattle-kraken-logo.png"
                  alt="Seattle Kraken Logo"
                  placeholder="blurred"
                  quality="100"
                  width={175}
                />
              </Link>
            </div>
            <div className="col-md-8 col-lg-9 pl-md-4">
              <h3>
                WaFd Bank is thrilled to be helping bring hockey back to Seattle and honored to be the exclusive bank of
                the Seattle Kraken.
              </h3>
              <p className="text-muted font-italic">
                &ldquo;WaFd Bank has been a committed partner from the beginning as the financing partner for the
                Northgate Ice Centre and we are so proud to welcome them formally to the Kraken family,&rdquo; said Tod
                Leiweke, CEO of the Seattle Kraken. &ldquo;Together we will work to support our fans and
                community.&rdquo;
              </p>
              <div className="row">
                <div className="col-sm-6 col-lg-4 mb-3 mb-md-0">
                  <Link
                    id="kraken-learn-more-btn"
                    to="/about-us/wafd-sports/seattle-kraken"
                    className="btn btn-block btn-primary"
                    role="button"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <a
                    id="kraken-video-modal-link"
                    className="btn btn-link btn-block"
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowKrakenVideo(e);
                    }}
                  >
                    Watch Video
                  </a>
                </div>
                <VideoModal
                  show={showKrakenVideo}
                  handleClose={handleCloseKrakenVideo}
                  {...{
                    id: "seattle-kraken-wafd-bank-video-modal",
                    VimeoID: "676101383",
                    Title: "Checking Shouldn't Have to Hurt - Anyone Need a Free Checking Account? We've Got a Guy"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Seattle Storm */}
      <section className="container">
        <div className="row">
          <div className="col-md-4 col-lg-3 text-center">
            <h2>Seattle Storm</h2>
            <Link id="clickable-storm-image" to="/about-us/wafd-sports/seattle-storm">
              <img src={LogoSeattleStorm} width={175} height={165} className="" alt="Seattle Storm Logo" />
            </Link>
          </div>
          <div className="col-md-8 col-lg-9 pl-md-4">
            <h3>
              WaFd Bank is proud to support women's sports, starting with the{" "}
              <Link to="/about-us/wafd-sports/seattle-storm" id="seattle-storm-link">
                Seattle Storm
              </Link>
              .
            </h3>
            <p className="text-muted font-italic">
              "We are thrilled to partner with the Storm to address equitable access to physical fitness within our area
              schools and to be given an opportunity to live our mission to make a difference by offering a top-quality
              financial literacy platform to our community for free," says Cathy Cooper, Executive Vice President and
              Chief Consumer Banker at WaFd Bank. "We look forward to kicking off the Fit Futures program this fall
              while supporting women's professional sports."
            </p>
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <Link
                  id="storm-learn-more-btn"
                  to="/about-us/wafd-sports/seattle-storm"
                  className="btn btn-block btn-primary"
                  role="button"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Seattle Seawolves */}
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 text-center">
              <h2>Seattle Seawolves</h2>
              <Link id="clickable-seawolves-image" to="/about-us/wafd-sports/seattle-seawolves">
                <StaticImage
                  src="../../../images/third-party/seattle-seawolves/seattle-seawolves-primary-logo.png"
                  alt="Seattle Seawolves Logo"
                  placeholder="blurred"
                  quality="100"
                  width={175}
                />
              </Link>
            </div>
            <div className="col-md-8 col-lg-9 pl-md-4">
              <h3>
                WaFd Bank is proud to support the growth of Seattle's professional rugby team, the Seattle Seawolves.
              </h3>

              <p>
                WaFd Bank has partnered with the Seattle Seawolves since the beginning of the Major League Rugby (MLR)
                franchise. We're committed to supporting the growth of this sport and are excited to offer more engaging
                fan opportunities through our partnership. Together, we hunt!
              </p>
              <p>Proud to defend the shield!</p>
              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <Link
                    id="seawolves-learn-more-btn"
                    to="/about-us/wafd-sports/seattle-seawolves"
                    className="btn btn-block btn-primary no-min-width"
                    role="button"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <VimeoVideoFrame {...stefanFreiPaintingVideo} />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              WaFd Bank is excited to partner with Seattle professional sports teams including the Seattle Kraken,
              Seattle Storm, and Seattle Seawolves.
            </h3>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default WaFdSports;
